export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    header: "Fiera Virtuale",
  },
  {
    title: "Gestione stand",
    route: "ListStand",
    icon: "GridIcon",
  },
  {
    title: "Totem",
    route: "totem",
    icon: "ColumnsIcon",
  },
  {
    title: "Meeting Points",
    route: "ListPoints",
    icon: "RepeatIcon",
  },
  {
    title: "Conference",
    route: "conference",
    icon: "VideoIcon",
  },
  {
    title: "Gestione visitatori",
    route: "users",
    icon: "EyeIcon",
  },
  {
    header: "Impostazioni",
  },
  {
    title: "Personalizzazioni",
    route: "personalizations",
    icon: "ImageIcon",
  },
  {
    title: "Notifiche",
    route: "notifications",
    icon: "BellIcon",
  },
  {
    title: "Gestione editori",
    route: "editors",
    icon: "Edit3Icon",
  },
  {
    title: "Statistiche",
    route: "stats",
    icon: "ActivityIcon",
  },
];
