<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-row class="match-height">
        <b-col md="6" lg="3">
          <b-card text-variant="center" class="card card-congratulations">
            <b-img
              :src="require('@/assets/images/elements/decore-left.png')"
              class="congratulations-img-left"
            />
            <b-img
              :src="require('@/assets/images/elements/decore-right.png')"
              class="congratulations-img-right"
            />

            <b-avatar variant="primary" size="70" class="shadow mb-2">
              <feather-icon size="28" icon="AwardIcon" />
            </b-avatar>
            <h1 class="mb-1 mt-50 text-white">
              {{ $t("stats.main_event") }}
            </h1>
            <b-card-text class="m-auto w-75">
              {{ $t("stats.stats_event") }}
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="6" lg="3">
          <statistic-card-with-area-chart
            icon="UserIcon"
            color="info"
            :statistic="statistics.first_visit"
            :statistic-title="$t('stats.visitors')"
            :chartData="data.visitors"
            class="stats-card"
          />
        </b-col>
        <b-col md="6" lg="3">
          <statistic-card-with-area-chart
            icon="ActivityIcon"
            color="success"
            :statistic="statistics.interactions"
            :statistic-title="$t('stats.interactions')"
            :chartData="data.interactions"
            class="stats-card"
          /> </b-col
        ><b-col md="6" lg="3">
          <statistic-card-with-area-chart
            icon="UserPlusIcon"
            color="danger"
            :statistic="statistics.sign_up"
            :statistic-title="$t('stats.user_registered')"
            :chartData="data.registrations"
            class="stats-card"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-7">
          <visitorsGraph
            :daily_visitors_dates="daily_visitors_dates"
            :daily_visitors_data="daily_visitors_data"
            :key="render_component"
          />
        </b-col>
        <b-col class="col-5">
          <interactionsGraph
            :daily_interactions_dates="daily_interactions_dates"
            :daily_interactions_data="daily_interactions_data"
            :key="render_component"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-12">
          <standsList
            ref="stand_list"
            :stands_list="stands_list"
            :sum_visits="sum_visits"
            :sum_interactions="sum_interactions"
            :sum_contacts="sum_contacts"
            :key="render_component"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col class="col-4">
          <donutDevices
            :device_categories="device_categories"
            :device_events="device_events"
            :key="render_component"
          />
        </b-col>
        <b-col class="col-3">
          <otherRooms
            :conference_access="conference_access"
            :meeting_points_access="meeting_points_access"
            :totems_access="totems_access"
            :media_access="media_access"
          />
        </b-col>
        <b-col class="col-5">
          <totemMediaGraph
            :totems_names="totems_names"
            :totems_access_stats="totems_access_stats"
            :media_access_stats="media_access_stats"
            :key="render_component"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BAvatar,
  BCardText,
  BRow,
  BCol,
  BOverlay,
} from "bootstrap-vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import visitorsGraph from "./statistics/visitors-graph.vue";
import interactionsGraph from "./statistics/interactions-graph.vue";
import standsList from "./statistics/stands-list.vue";
import donutDevices from "./statistics/donut-devices-chart.vue";
import otherRooms from "./statistics/statistics-rooms.vue";
import totemMediaGraph from "./statistics/totem-media-graph.vue";
import analytics from "@api/analytics";
import { getAllStands } from "@api/stands";
import { getAllTotems } from "@api/totems.js";

export default {
  components: {
    BCard,
    BImg,
    BAvatar,
    BCardText,
    BRow,
    BCol,
    StatisticCardWithAreaChart,
    visitorsGraph,
    interactionsGraph,
    standsList,
    donutDevices,
    otherRooms,
    totemMediaGraph,
    BOverlay,
  },
  data() {
    return {
      daily_visitors_dates: [],
      daily_visitors_data: [],
      daily_interactions_dates: [],
      daily_interactions_data: [],
      stands_list: [],
      statistics: {
        first_visit: 0,
        interactions: 0,
        sign_up: 0,
      },
      data: {
        //Valori randomici per poter disegnare la curva a scopo grafico.
        visitors: [
          {
            name: "Visite",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
          },
        ],
        interactions: [
          {
            name: "Interazioni",
            data: [10, 41, 35, 49, 25, 69, 62, 101, 45],
          },
        ],
        registrations: [
          {
            name: "Utenti registrati",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 23],
          },
        ],
      },
      render_component: 0,
      sum_visits: 0,
      sum_interactions: 0,
      sum_contacts: 0,
      device_categories: [],
      device_events: [],
      meeting_points_to_sum: [],
      totems_access_stats: [0, 0, 0, 0, 0, 0],
      media_access_stats: [0, 0],
      conference_access: 0,
      meeting_points_access: 0,
      totems_access: 0,
      media_access: 0,
      totems_names: [],
      show: false,
    };
  },
  created() {
    if (analytics.checkBrowser()) {
      this.show = true;
      if (this.rangeDate) {
        this.getReportForDateRanges(
          this.rangeDate.split("to")[0],
          this.rangeDate.split("to")[1]
        );
      } else {
        this.getReports();
      }
      this.getStandsList();
      this.getTotemsNames();
    } else {
      this.$swal({
        title:
          '<span class="font-weight-bolder">' +
          this.$t("alert_title_warning") +
          "</span>",
        icon: "warning",
        text:
          "Statistiche non disponibili, accertarsi di usare un browser tra Chrome, Firefox o Edge.",
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
        .then((result) => {
          this.$router.push({ path: "/" });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    document.getElementById(
      "breadcrumbsPageTitle"
    ).innerHTML = this.$route.meta.pageTitle;
  },
  methods: {
    getStandsList() {
      getAllStands().then((data) => {
        for (let i = 0; i < data.length; i++) {
          this.stands_list.push({
            id: data[i].id,
            logo_url: data[i].logo_url,
            name: data[i].name,
            external_ref: data[i].external_ref,
            type: data[i].type.label,
            visits: 0,
            pinpoints: {
              type: [],
              click: [],
              sum_click: 0,
            },
            contacts: 0,
          });
        }
        this.$store.commit("set_list", this.stands_list);
      });
    },
    getTotemsNames() {
      getAllTotems()
        .then((data) => {
          console.log(data);
          for (let i = 0; i < data.idea_workshop.length; i++) {
            this.totems_names.push(data.idea_workshop[i].label);
            if (i == data.idea_workshop.length - 1) {
              this.totems_names.push(data.media_press[0].label);
              this.totems_names.push(data.media_press[1].label);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReports() {
      let $this = this;
      analytics
        .defaultReport()
        .then((data) => {
          console.log(data);
          for (let i = 0; i < data.length; i++) {
            if (data[i].dimensionValues[0].value == "first_visit") {
              $this.statistics.first_visit = data[i].metricValues[0].value;
            } else if (data[i].dimensionValues[0].value == "page_view") {
              $this.statistics.interactions = data[i].metricValues[0].value;
            } else if (data[i].dimensionValues[0].value == "sign_up") {
              $this.statistics.sign_up = data[i].metricValues[0].value;
            } else if (
              data[i].dimensionValues[0].value.includes("visit_stand_")
            ) {
              $this.getVisitsStatsStands(data[i]);
            } else if (
              data[i].dimensionValues[0].value.includes("open_pinpoint_")
            ) {
              $this.getPinPointsStatsStands(data[i]);
            } else if (
              data[i].dimensionValues[0].value.includes("send_contact_")
            ) {
              $this.getContactsStatsStands(data[i]);
            } else {
              $this.getOtherRoomsStats(data[i]);
            }
          }
          $this.getSumStatitisticsCards();
          $this.getDailyVisitorsDefaultReport();
          $this.getDailyInteractionsDefaultReport();
          $this.getDeviceCategoryDefaultReport();
          $this.forceUpdate();
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    getOtherRoomsStats(data) {
      let $this = this;
      if (data.dimensionValues[0].value == "open_conference") {
        $this.conference_access = data.metricValues[0].value;
      }
      if (data.dimensionValues[0].value.includes("open_meeting")) {
        $this.meeting_points_to_sum.push(parseInt(data.metricValues[0].value));
        $this.meeting_points_access = $this.meeting_points_to_sum.reduce(
          (a, b) => a + b,
          0
        );
      }
      if (data.dimensionValues[0].value.includes("open_totem_idea")) {
        $this.totems_access_stats.unshift(parseInt(data.metricValues[0].value));
        $this.totems_access = $this.totems_access_stats.reduce(
          (a, b) => a + b,
          0
        );
        if ($this.totems_access_stats.length > 6) {
          $this.totems_access_stats.pop();
          return;
        }
        if ($this.totems_access_stats.length == 6) {
          $this.totems_access_stats.push(0, 0);
        }
      }
      if (data.dimensionValues[0].value.includes("open_totem_media")) {
        $this.media_access_stats.push(parseInt(data.metricValues[0].value));
        $this.media_access = $this.media_access_stats.reduce(
          (a, b) => a + b,
          0
        );
        if ($this.media_access_stats.length > 2) {
          $this.media_access_stats.splice(
            $this.media_access_stats.lastIndexOf(0),
            1
          );
        }
        if ($this.media_access_stats.length == 2) {
          $this.media_access_stats.unshift(0, 0, 0, 0, 0, 0);
        }
      }
    },
    getVisitsStatsStands(data) {
      let $this = this;
      let stand_id = data.dimensionValues[0].value.split("_")[2];
      if (stand_id) {
        let index = $this.stands_list.findIndex(
          (x) => x.id == parseInt(stand_id)
        );
        $this.stands_list[index].visits = parseInt(data.metricValues[0].value);
      }
    },
    getPinPointsStatsStands(data) {
      let $this = this;
      let stand_id, pinpoint;
      if (data.dimensionValues[0].value.includes("company_webinar_")) {
        stand_id = data.dimensionValues[0].value.split("_")[4];
        pinpoint =
          data.dimensionValues[0].value.split("_")[2] +
          " " +
          data.dimensionValues[0].value.split("_")[3];
      } else {
        stand_id = data.dimensionValues[0].value.split("_")[3];
        pinpoint = data.dimensionValues[0].value.split("_")[2];
      }
      let index = $this.stands_list.findIndex(
        (x) => x.id == parseInt(stand_id)
      );
      $this.stands_list[index].pinpoints.type.push(pinpoint);
      $this.stands_list[index].pinpoints.click.push(
        parseInt(data.metricValues[0].value)
      );
      $this.stands_list[index].pinpoints.sum_click = $this.stands_list[
        index
      ].pinpoints.click.reduce((a, b) => a + b, 0);
    },
    getContactsStatsStands(data) {
      let $this = this;
      let stand_id = data.dimensionValues[0].value.split("_")[2];
      let index = $this.stands_list.findIndex(
        (x) => x.id == parseInt(stand_id)
      );
      $this.stands_list[index].contacts = parseInt(data.metricValues[0].value);
    },
    getSumStatitisticsCards() {
      let $this = this;
      let visits = [];
      let interactions = [];
      let contacts = [];
      for (let i = 0; i < $this.stands_list.length; i++) {
        visits.push($this.stands_list[i].visits);
        interactions.push($this.stands_list[i].pinpoints.sum_click);
        contacts.push($this.stands_list[i].contacts);
      }
      $this.sum_visits = visits.reduce((a, b) => a + b, 0);
      $this.sum_interactions = interactions.reduce((a, b) => a + b, 0);
      $this.sum_contacts = contacts.reduce((a, b) => a + b, 0);
    },
    getDailyVisitorsDefaultReport() {
      analytics
        .getDailyVisitorsDefaultReport()
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            this.formatDateforGraphs(data[i].dimensionValues[0].value);
            this.daily_visitors_data.push(data[i].metricValues[0].value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDailyInteractionsDefaultReport() {
      analytics
        .getDailyInteractionsDefaultReport()
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            this.daily_interactions_data.push(data[i].metricValues[0].value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDeviceCategoryDefaultReport() {
      let $this = this;
      analytics
        .getDeviceCategoryDefaultReport()
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            $this.device_categories.push(data[i].dimensionValues[0].value);
            $this.device_events.push(parseInt(data[i].metricValues[0].value));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportForDateRanges(start_date, end_date) {
      let $this = this;
      analytics
        .getReportForDateRanges(
          start_date.replace(/\s/g, ""),
          end_date.replace(/\s/g, "")
        )
        .then((data) => {
          console.log(data);
          $this.resetAllData();
          for (let i = 0; i < data.length; i++) {
            if (data[i].dimensionValues[0].value == "first_visit") {
              $this.statistics.first_visit = data[i].metricValues[0].value;
            } else if (data[i].dimensionValues[0].value == "page_view") {
              $this.statistics.interactions = data[i].metricValues[0].value;
            } else if (data[i].dimensionValues[0].value == "sign_up") {
              $this.statistics.sign_up = data[i].metricValues[0].value;
            } else if (
              data[i].dimensionValues[0].value.includes("visit_stand_")
            ) {
              $this.getVisitsStatsStands(data[i]);
            } else if (
              data[i].dimensionValues[0].value.includes("open_pinpoint_")
            ) {
              $this.getPinPointsStatsStands(data[i]);
            } else if (
              data[i].dimensionValues[0].value.includes("send_contact_")
            ) {
              $this.getContactsStatsStands(data[i]);
            } else {
              $this.getOtherRoomsStats(data[i]);
            }
          }
          $this.getSumStatitisticsCards();
          $this.getDailyVisitorsReportForDateRanges(
            start_date.replace(/\s/g, ""),
            end_date.replace(/\s/g, "")
          );
          $this.getDailyinteractionsReportForDateRanges(
            start_date.replace(/\s/g, ""),
            end_date.replace(/\s/g, "")
          );
          $this.getDeviceCategoryReportForDateRanges(
            start_date.replace(/\s/g, ""),
            end_date.replace(/\s/g, "")
          );
          $this.forceUpdate();
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetAllData() {
      this.daily_visitors_dates = [];
      this.daily_interactions_dates = [];
      this.daily_visitors_data = [];
      this.daily_interactions_data = [];
      this.sum_visits = 0;
      this.sum_interactions = 0;
      this.sum_contacts = 0;
      this.device_categories = [];
      this.device_events = [];
      this.meeting_points_to_sum = [];
      this.totems_access_stats = [0, 0, 0, 0, 0, 0];
      this.media_access_stats = [0, 0];
      this.conference_access = 0;
      this.meeting_points_access = 0;
      this.totems_access = 0;
      this.media_access = 0;
    },
    getDailyVisitorsReportForDateRanges(start_date, end_date) {
      analytics
        .getDailyVisitorsReportForDateRanges(start_date, end_date)
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            this.formatDateforGraphs(data[i].dimensionValues[0].value);
            this.daily_visitors_data.push(data[i].metricValues[0].value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDailyinteractionsReportForDateRanges(start_date, end_date) {
      analytics
        .getDailyinteractionsReportForDateRanges(start_date, end_date)
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            this.daily_interactions_data.push(data[i].metricValues[0].value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDeviceCategoryReportForDateRanges(start_date, end_date) {
      let $this = this;
      analytics
        .getDeviceCategoryReportForDateRanges(start_date, end_date)
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            $this.device_categories.push(data[i].dimensionValues[0].value);
            $this.device_events.push(parseInt(data[i].metricValues[0].value));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDateforGraphs(date) {
      let date_splitted = date.split("");
      let month = date_splitted[4] + date_splitted[5];
      let day = date_splitted[6] + date_splitted[7];
      this.daily_visitors_dates.push(day + "/" + month);
      this.daily_interactions_dates.push(day + "/" + month);
    },
    forceUpdate() {
      this.render_component += 1;
    },
  },
  computed: {
    rangeDate: function() {
      return this.$store.state.statistics.rangeDate;
    },
  },
  watch: {
    rangeDate: function() {
      if (this.rangeDate) {
        this.getReportForDateRanges(
          this.rangeDate.split("to")[0],
          this.rangeDate.split("to")[1]
        );
      }
    },
  },
};
</script>
