<template>
  <b-overlay :show="show_loader" rounded="sm">
    <b-row>
      <b-col md="12">
        <b-card no-body>
          <b-card-body>
            <b-card-title>{{ $t("Registry") }}</b-card-title>
            <b-card-sub-title>{{ $t("registry_subtitle") }}</b-card-sub-title>
          </b-card-body>
          <b-card-body>
            <b-row>
              <input-file
                v-for="item in items.registry"
                :key="item.id"
                :ref="item.slug"
                :data="item"
                @open_editor="openEditor"
              ></input-file>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card no-body>
          <b-card-body>
            <b-card-title>{{ $t("Sponsor_pannel") }}</b-card-title>
            <b-card-sub-title>{{
              $t("sponsor_pannel_subtitle")
            }}</b-card-sub-title>
          </b-card-body>
          <b-card-body>
            <b-row>
              <input-file
                v-for="item in items.sponsor"
                :key="item.id"
                :ref="item.slug"
                :data="item"
                @open_editor="openEditor"
              ></input-file>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card no-body>
          <b-card-body>
            <b-card-title>{{ $t("Sitting_room") }}</b-card-title>
            <b-card-sub-title>{{
              $t("sitting_room_subtitle")
            }}</b-card-sub-title>
          </b-card-body>
          <b-card-body>
            <b-row>
              <input-file
                v-for="item in items.lounge"
                :key="item.id"
                :ref="item.slug"
                :data="item"
                @open_editor="openEditor"
              ></input-file>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <modal-editor
        :image="file"
        :type="type"
        :width="width"
        :height="height"
        @onSave="showPreview"
        @close="abortImageEdit"
      >
      </modal-editor>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BButton,
  BOverlay,
} from "bootstrap-vue";

import inputFile from "./input-img-preview.vue";
import ModalEditor from "../editor.vue";

import { getAllPersonalizations } from "@api/personalizations";

export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BButton,
    inputFile,
    ModalEditor,
    BOverlay,
  },
  data() {
    return {
      file: null,
      type: "",
      width: 0,
      height: 0,
      show_loader: true,
      crop_limit: {
        registry_panel_1: {
          width: 711,
          height: 1000,
        },
        registry_panel_2: {
          width: 711,
          height: 1000,
        },
        registry_panel_3: {
          width: 711,
          height: 1000,
        },
        registry_panel_4: {
          width: 711,
          height: 1000,
        },
        sponsor: {
          width: 1200,
          height: 677,
        },
        partner: {
          width: 1200,
          height: 677,
        },
        panel: {
          width: 1500,
          height: 840,
        },
        totem: {
          width: 368,
          height: 768,
        },
        internal: {
          width: 1500,
          height: 840,
        },
      },
      items: {},
    };
  },
  created() {
    this.getPersonalizationsData();
  },
  mounted() {
    document.getElementById(
      "breadcrumbsPageTitle"
    ).innerHTML = this.$route.meta.pageTitle;
  },
  methods: {
    getPersonalizationsData() {
      this.show_loader = true;
      const $this = this;
      getAllPersonalizations()
        .then((data) => {
          $this.items = data;
          $this.show_loader = false;
        })
        .catch(() => {
          this.$router.push({ path: `/error-404/` });
          $this.show_loader = false;
        });
    },
    openEditor(file, type) {
      this.file = file;
      this.type = type;
      this.width = this.crop_limit[type].width;
      this.height = this.crop_limit[type].height;
      this.$bvModal.show("modal-editor");
    },
    abortImageEdit(type) {
      this.$refs[type][0].file = null;
    },
    showPreview(type, imageUrl, image = null) {
      this.$refs[type][0].file = image;
      this.$refs[type][0].preview_image = imageUrl;
      this.$refs[type][0].saveImage();
    },
  },
};
</script>

<style lang="scss"></style>
