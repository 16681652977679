<template>
  <b-modal
    id="modal-company"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    hideHeaderClose
    no-close-on-backdrop
    @ok.prevent="saveData"
    @cancel="resetModal"
    @show="showModal"
    size="lg"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder" v-if="!editTitle">
            {{ pinpoint.label }}
            <b-button
              v-if="pinpoint.type.editable"
              class="btn-icon rounded-circle"
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                @click="editTitle = true"
                variant="flat-primary"
              />
            </b-button>
          </h2>
          <b-form-input
            v-if="editTitle"
            type="text"
            v-model="pinpoint.label"
            maxlength="25"
            autofocus
          />
          <span>{{ $t("pin_point.Info_company") }}</span>
        </b-col>
        <b-col md="12">
          <b-form-group :label="$t('pin_point.Add_company_profile')">
            <b-form-file
              v-model="file"
              :placeholder="$t('any_file_select')"
              accept=".pdf"
              @input="filePreview"
            />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="file_url">
          <a :href="file_url" target="_blank">
            <feather-icon icon="FileIcon" /> {{ file_name }}
          </a>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="pin_hide">
          <icon-selector
            v-if="pinpoint.type.editable"
            v-model="icon"
          ></icon-selector>
          <footer-pin-point v-model="hide_pinpont"></footer-pin-point>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BForm,
  BButton,
  BModal,
  BFormFile,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "./footer";
import IconSelector from "./icon-selector";

import { updatePinpoint } from "@api/pinpoint";

export default {
  props: ["pinpoint"],
  name: "pin-point-company",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
    BFormFile,
    BOverlay,
    FooterPinPoint,
    IconSelector,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      file: null,
      file_url: null,
      fields: { ...this.pinpoint.fields },
      hide_pinpont: !this.pinpoint.active,
      file_name: "",
      editTitle: false,
      icon: this.pinpoint.icon,
      show: false,
    };
  },
  methods: {
    showModal() {
      this.file_url = null;
      this.file = null;
      if (this.pinpoint.fields.file) {
        this.file_url = this.pinpoint.fields.file.url;
        this.file_name = this.pinpoint.fields.file.name;
      }
      this.icon = this.pinpoint.icon;
    },
    saveData() {
      const $this = this;
      this.show = true;
      let formData = new FormData();
      formData.append("label", $this.pinpoint.label);
      if ($this.file) {
        formData.append("file", $this.file);
      }
      formData.append("icon", $this.icon);
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      updatePinpoint($this.pinpoint.id, formData)
        .then((data) => {
          this.$emit("onSave");
          this.$bvModal.hide("modal-company");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    filePreview() {
      this.file_url = null;
      this.file_name = this.file.name;
    },
    resetModal() {
      this.fields = { ...this.pinpoint.fields };
      this.hide_pinpont = !this.pinpoint.active;
      this.icon = this.pinpoint.icon;
      this.$bvModal.hide("modal-company");
    },
    modalHidden() {
      this.editTitle = false;
    },
  },
};
</script>

<style></style>
